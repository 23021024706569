@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: 'lab', system-ui, sans-serif;
      font-kerning: normal;
      @apply 4xl:text-xl 5xl:text-lg;
      /* font-feature-settings: "kern" 1, "liga" 1; */
    }
  }